<template>
  <div class="volunteer">
    <div class="head">
      <img src="@/assets/images/plant.svg">
      <span>"두리원 이용자들은 행복을 향해 나아가고 있습니다.<br>함께가는 아름다운 세상에 동행해주세요"</span>
    </div>
    <div class="how">
      <div class="item">
        <span class="title">생활지원</span>
        <span class="substance">이용자 돌봄, 목욕 및 세탁 지원 등</span>
      </div>
      <div class="item">
        <span class="title">식사지원</span>
        <span class="substance">이용자 식사 및 배식 지원</span>
      </div>
      <div class="item">
        <span class="title">재능기부</span>
        <span class="substance">공연, 이&middot;미용, 교육제공 등</span>
      </div>
      <div class="item">
        <span class="title">프로그램지원</span>
        <span class="substance">산책, 여행, 문화체험 등 동행</span>
      </div>
      <div class="item">
        <span class="title">시설환경지원</span>
        <span class="substance">청소 및 환경관리</span>
      </div>
      <div class="item">
        <span class="title">행사지원</span>
        <span class="substance">바자회, 일일찻집, 일일밥집 등 행사 지원</span>
      </div>
    </div>
    <div class="info">
      <div class="item">
        <span class="title">자원봉사 신청 방법</span>
        <div class="substance">
          <span class="row">방문접수 또는 전화접수</span>
          <span class="row">문의처 : 031-593-5636</span>
        </div>
      </div>
      <div class="item">
        <span class="title">봉사활동 인증</span>
        <div class="substance">
          <span class="row">1일 최대 8시간 인정</span>
          <span class="row">시작시간 ~ 종료시간으로 계산</span>
          <span class="row">타인에게 양도 불가능</span>
          <span class="row">VMS와 1365를 통해 인증</span>
        </div>
      </div>
    </div>
    <div class="link">
      <a class="btn" href="https://www.vms.or.kr/main.do" target="_blank">
        <img src="@/assets/images/logo-vms.png">
        <span>VMS</span>
      </a>
      <a class="btn" href="https://www.1365.go.kr/vols/main.do" target="_blank">
        <img src="@/assets/images/logo-1365.png">
        <span>1365</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportVolunteer'
}
</script>

<style scoped>
@import "@/styles/Support.css";
</style>
