<template>
  <div id="footer">
    <span>대표이사 김선숙 / 경기도 남양주시 수동면 비룡로908번길 52-19</span>
    <span>전화 031-593-5636 / 팩스 031-595-5636</span>
    <span>Copyright&copy; 2023 사회복지법인두리원 All Rights Reserved. Designed by QnSoft</span>
  </div>
</template>

<script>

</script>

<style>
@import "@/styles/Footer.css";
</style>
