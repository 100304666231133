<template>
  <Header :isLogined="isLogined" :modal="modal"/>
  <router-view :isLogined="isLogined" @click="test"/>
  <Footer />
  <ModalComponent ref="modal" />
</template>
<script>
import {defineComponent, onBeforeMount, onMounted, ref, watch} from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {useRoute, useRouter} from "vue-router";
import ModalComponent from "@/components/ModalComponent.vue";

export default defineComponent({
  components: { Footer, Header, ModalComponent },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const modal = ref(null)
    const isLogined = ref(false)

    watch(route, (newVal) => {
      window.scrollTo(0,0)
      /*loginCheck().then((response) => {
        isLogined.value = response.status == 200
      }).catch((err) => {
        isLogined.value = false
        if(err.response.status == 401 && newVal.path.endsWith('edit')) {
          router.push('/')
        }
      })*/
    })

    const modalOpen = () => {
      modal.value.show()
    }

    const test = () => {
      const target = document.querySelector(".headerSmall .headerMenu")
      target.classList.remove('active')
    }

    return {
      isLogined, modalOpen, modal, test
    }
  }
})
</script>
<style>
@import "@/styles/Common.css";
</style>

