<template>
  <router-link :to="`/post/${type}/add`" v-slot="{navigate}" custom>
    <button class="btnAdd" type="button" @click="navigate">
      <img src="@/assets/images/pencil.svg">
      <span>글쓰기</span>
    </button>
  </router-link>
</template>

<script>

export default {
  name: "WriteBtn",
  props: ['type']
}
</script>

<style scoped>

</style>
