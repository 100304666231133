<template>
  <div class="company">
    <div class="head">
      <img src="@/assets/images/logo_no_text.png">
<!--      <span><span class="highlight">두리원</span>은 2001년 설립되어 행복을 향한 희망둥지로써 함께가는 아름다운 세상을 만들어가고 있습니다</span>-->
      <span><span class="highlight companyIntroduce">두리원</span>은 2001년 직업재활을 시작으로 2006년 개인운영 신고시설로 운영해오다 2019년 정원 21명의 사회복지법인두리원으로 전환, 유형별거주시설로 운영되고 있습니다. 시설은 열악하지만 각자 개인별 욕구에 맞춰 건강하고 활동적인 서비스를 지원하고 있습니다.</span>
    </div>
    <div class="body">
      <div class="reason spirit">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/bulb.svg">
          </div>
          <span>설립이념</span>
        </div>
        <span class="text">장애인 거주시설로써 일상생활에 불편한 장애인의 재활을 돕고 다양한 프로그램을 운영하여 이용인의 잔존하는 능력개발과 더불어 생활인 모두가 행복하고 건강한 삶의 주인공이 되고자 합니다.</span>
      </div>
      <div class="reason goal">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/goal.svg">
          </div>
          <span>사업목표</span>
        </div>
        <span class="text">빠르고 다양하게 변화되는 현실사회에 따른 장애인 문제는 본인 및 가족의 경제적, 정신적인 고통을 지속적으로 가중시키고 있기에 그에 따른 부작용에 능동적으로 대처하기 위한 전문적인 장애인복지 서비스를 지원하고자 합니다.</span>
      </div>
      <div class="reason motto">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/star.svg">
          </div>
          <span>원훈</span>
        </div>
        <div class="substance">
          <div class="item">
            <div class="icon">
              <img src="@/assets/images/family.svg">
            </div>
            <span>가족적인 분위기</span>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/images/mission.svg">
            </div>
            <span>자립</span>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/images/conversation.svg">
            </div>
            <span>소통</span>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/images/respect.svg">
            </div>
            <span>존중</span>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/images/sprout.svg">
            </div>
            <span>성장</span>
          </div>
        </div>
      </div>
      <div class="reason business">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/list.svg">
          </div>
          <span>주요사업</span>
        </div>
        <div class="body">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>장애인복지법 제58조의 ‘장애인복지시설’ 중 ‘장애유형별 거주 시설’ 의 설치 및 운영을 위한 사업</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>그 밖에 법인 목적달성에 필요한 사업</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>다양한 프로그램과 지역연계 서비스, 문화생활 등 개인별 맞춤 서비스 제공</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>이용자의 자립지원 프로그램과, 그에 필요한 교육 제공</span>
          </div>
        </div>
      </div>
      <div class="info">
        <span class="title">기본현황</span>
        <div class="body">
          <div class="item">
            <span class="classification">시설장</span>
            <span class="input">김선숙</span>
          </div>
          <div class="item">
            <span class="classification">전화번호</span>
            <span class="input">031-593-5636</span>
          </div>
          <div class="item">
            <span class="classification">팩스번호</span>
            <span class="input">031-595-5636</span>
          </div>
          <div class="item">
            <span class="classification">시설종류</span>
            <span class="input">장애인거주시설</span>
          </div>
          <div class="item">
            <span class="classification">대지면적</span>
            <span class="input">540m<sup>2</sup></span>
          </div>
          <div class="item">
            <span class="classification">연면적</span>
            <span class="input">269,54m<sup>2</sup>&nbsp;(지상2층 / 다락)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroCompany'
}
</script>

<style scoped>
@import "@/styles/Introduce.css";
</style>
