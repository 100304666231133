import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'

import Introduce from '@/views/Introduce/Introduce.vue'
import IntroCompany from '@/views/Introduce/IntroCompany.vue'
import IntroGreeting from '@/views/Introduce/IntroGreeting.vue'
import IntroHistory from '@/views/Introduce/IntroHistory.vue'
import IntroGuide from '@/views/Introduce/IntroGuide.vue'

import Service from '@/views/Service/Service.vue'
import ServicePrograms from '@/views/Service/ServicePrograms.vue'
import ServiceRights from '@/views/Service/ServiceRights.vue'

import Support from '@/views/Support/Support.vue'
import SupportDonate from '@/views/Support/SupportSponse.vue'
import SupportVolunteer from '@/views/Support/SupportVolunteer.vue'

import Post from '@/views/Post/Post.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: Introduce,
    children: [
      {
        path: '',
        name: 'IntroCompany',
        component: IntroCompany
      },
      {
        path: 'greeting',
        name: 'IntroGreeting',
        component: IntroGreeting
      },
      {
        path: 'history',
        name: 'IntroHistory',
        component: IntroHistory
      },
      {
        path: 'guide',
        name: 'IntroGuide',
        component: IntroGuide
      }
    ]
  },
  {
    path: '/service',
    name: 'service',
    component: Service,
    children: [
      {
        path: '',
        name: 'ServicePrograms',
        component: ServicePrograms
      },
      {
        path: 'rights',
        name: 'ServiceRights',
        component: ServiceRights
      }
    ]
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    children: [
      {
        path: '',
        name: 'SupportDonate',
        component: SupportDonate
      },
      {
        path: 'volunteer',
        name: 'SupportVolunteer',
        component: SupportVolunteer
      }
    ]
  },
  {
    path: '/post/:type/:detail?',
    name: 'post',
    component: Post,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
