<template>
  <div id="main" class="pagePost">
    <span class="pageTitle">게시판</span>
    <div class="navBar">
      <router-link class="nav" :class="{'router-link-exact-active': type == 'notice'}"  to="/post/notice">공지사항</router-link>
      <router-link class="nav" :class="{'router-link-exact-active': type == 'free'}"    to="/post/free">자유게시판</router-link>
      <router-link class="nav" :class="{'router-link-exact-active': type == 'gallery'}" to="/post/gallery">갤러리</router-link>

    </div>
    <router-view />
    <div id="pageContent">
      <TableBoard v-if="(type == 'notice' || type == 'free') && detail != 'detail' && detail != 'add' && detail != 'edit'" :type="type" :page="page" :list="items">
        <template #writeBtn>
          <WriteBtn :type="type" v-if="logined"/>
        </template>
        <template #paging>
          <ListControl :type="type" :page="page" :pageSize="type == 'gallery' ? 12 : 10"/>
        </template>
      </TableBoard>
      <GalleryBoard v-if="type == 'gallery' && detail != 'detail' && detail != 'add' && detail != 'edit'" :type="type" :page="page" :list="items">
        <template #writeBtn>
          <WriteBtn :type="type" v-if="logined"/>
        </template>
        <template #paging>
          <ListControl :type="type" :page="page" :pageSize="type == 'gallery' ? 12 : 10"/>
        </template>
      </GalleryBoard>
      <DetailView v-if="detail == 'detail' || detail == 'add' || detail == 'edit'" :seq="seq" :type="type" :isLogined="logined"></DetailView>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import TableBoard from "@/components/TableBoard.vue"
import ListControl from "@/components/ListControl.vue";
import GalleryBoard from "@/components/GalleryBoard.vue"
import DetailView from "@/views/Post/DetailView.vue";
import WriteBtn from "@/components/WriteBtn.vue";
import {isAuthenticated} from "@/utils/jwt.js";

import { getBoardList} from '@/utils'

import {onBeforeMount, onMounted, onUpdated, ref, watch, watchEffect} from "vue";
import jwt from '@/utils/jwt'

export default {
  name: 'Post',
  components: {
    TableBoard, ListControl, GalleryBoard, DetailView, WriteBtn
  },
  props: ['isLogined'],
  setup (props) {
    const route = useRoute()
    const type = ref(route.params.type)
    const page = ref(1)
    const detail = ref(route.params.detail)
    const seq = ref(route.query.seq)
    const items = ref([])
    const logined = ref()

    watch(route, (value, oldValue, onCleanup) => {
      type.value = value.params.type
      detail.value = value.params.detail
      page.value = !value.query.page ? 1 : value.query.page

      if(value.query.seq) {
        seq.value = value.query.seq
      }
      loadItems()
    })

    watchEffect(() => {
      logined.value = jwt.isAuthenticated()
    })

    onMounted(() => {
      if(!detail.value) {
        loadItems()
      }
    })


    const loadItems = () => {
      if(detail.value == '') {
        items.value = []
        const limit = type.value == 'gallery' ? 12 : 10
        getBoardList(type.value, page.value, limit)
          .then((response) => {
            if(response.status == 200) {
              items.value = response.data
            }
          })
          .catch((err) => {
            console.log(err)
            items.value = []
          })
      }
    }

    return {
      items, type, page, detail, seq, logined
    }
  }
}
</script>

<style scoped>
@import "@/styles/post.css";
</style>
