import axios from 'axios'
import jwt from './jwt'
const http = axios.create()
import router from '@/router/index'
http.interceptors.request.use(
  config => {
    if(config.url.startsWith("/api/board/upload")) {

      const isAuthenticated = jwt.isAuthenticated()

      if(isAuthenticated) {
        config.headers.Authorization = jwt.getToken()
      }
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error.response) {
      switch (error.response.status) {
        case 403:
          router.push('/').catch(() => {})
      }
    }
    return Promise.reject(error)
  }
)

let loginFlag = false

export const convertDate = (value) => {
  return value.replace(/^(\d{4})(\d{2})(\d{2})(\d*)/, '$1.$2.$3')
}
export const setLoginFlag = (flag) => {
  loginFlag = flag
}

export const getLoginFlag = () => {
  return loginFlag
}

export const getBoardList = (type, page, limit) => {
  return http.get(`/api/board?type=${type}&page=${page}&limit=${limit}&offset=${(page-1) * limit}`)
}

export const getBoardListCount = (type) => {
  return http.get(`/api/board/count?type=${type}`)
}

export const getBoardDetail = (type, seq) => {
  return http.get(
    `/api/board/detail?type=${type}&seq=${seq}`
  )
}

export const saveItemRequest = (type, formData, contentLength) => {
  return http.post(
    `/api/board/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "ContentLength" : contentLength
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      emulateJSON: true,
    }
  )
}

export const deleteItem = (type, seq) => {
  return http.post(
    `/api/board/delete?type=${type}&seq=${seq}`
  )
}

export const login = (id, pw) => {
  return http.post(
    `/api/login`,
    {username: id, password: pw}
  )
}

export const loginCheck = () => {
  return http.get(
    '/api/login/check'
  )
}

export const logout = () => {
  return http.get(
    '/api/logout'
  )
}
