<template>
  <div id="main" class="pageHome">
    <div class="homeSlide intro">
      <span class="introSlogan">"함께가는 아름다운 세상의 시작"</span>
      <span class="introTitle">행복을 향한 희망둥지, 두리원</span>
      <a class="introCall" href="tel:031-593-5636">
        <img src="@/assets/images/icon-phone.svg">
        <span>문의전화</span>
        <span>031-593-5636</span>
      </a>
    </div>
    <div class="homeSlide gallery">
      <div class="galleryTitle">
        <img src="@/assets/images/icon-gallery.svg">
        <span>갤러리</span>
      </div>
      <div class="galleryContainer">
        <button class="galleryControl prev" type="button">
          <img src="@/assets/images/angle-left.svg">
        </button>
        <swiper class="gallerySwiper" :navigation="galleryControl" :modules="modules"
                :breakpoints="{
          '320': {
            spaceBetween: 8,
            slidesPerView: 1
          },
          '768': {
            spaceBetween: 16,
            slidesPerView: 2
          },
          '1024': {
            spaceBetween: 16,
            slidesPerView: 3
          },
          '1400': {
            spaceBetween: 16,
            slidesPerView: 4
            }
                }">
          <swiper-slide class="gallerySlide" v-for="item in galleryList" :key="item.seq">
            <router-link :to="`/post/gallery/detail?seq=${item.seq}`" v-slot="{navigate}" custom >
              <img v-if="item.attachImageYn == 'Y'" :src="`/api/file/gallery/${item.thumbnail}`" @click="navigate">
              <img v-else src="@/assets/images/no-image.jpg" @click="navigate">
              <div class="slideTitle">
                <span class="titleName" @click="navigate">{{item.title}}</span>
                <span class="titleDate">{{ convertDate(item.regDate) }}</span>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
        <button class="galleryControl next" type="button">
          <img src="@/assets/images/angle-right.svg">
        </button>
      </div>
      <router-link class="galleryLink" to="/post/gallery">갤러리 전체보기</router-link>
    </div>
    <div class="homeSlide support">
      <div class="supportTitle">
        <span>작은 마음과 손길이 모여 두리원에</span>
        <span>안정과 행복을 만들어 주시길 바램합니다</span>
      </div>
      <div class="supportContainer">
        <router-link class="supportLink" to="/support">
          <img src="@/assets/images/icon-heart.svg">
          <span>후원 안내</span>
        </router-link>
        <router-link class="supportLink" to="/support/volunteer">
          <img src="@/assets/images/icon-volunteer.svg">
          <span>자원봉사 안내</span>
        </router-link>
      </div>
    </div>
    <div class="homeSlide info">
      <div class="notice">
        <div class="noticeHead">
          <img src="@/assets/images/icon-notice.svg">
          <span>공지사항</span>
        </div>
        <div class="noticeBody">
          <div class="noticeItem" v-for="item in noticeList">
            <span class="itemTitle">
              <router-link :to="`/post/notice/detail?seq=${item.seq}`">{{ item.title}}</router-link>
            </span>
            <span class="itemDate">{{ convertDate(item.regDate)}}</span>
          </div>
        </div>
      </div>
      <div class="guide">
        <router-link class="guideLink introduce" to="/introduce/guide">
          <div class="icon">
            <img src="@/assets/images/icon-map.svg">
          </div>
          <span>오시는 길</span>
        </router-link>
        <a class="guideLink map naver" href="https://map.naver.com/v5/directions/-/14173184.109273124,4540214.39196134,%EB%91%90%EB%A6%AC%EC%9B%90,12791087,PLACE_POI/-/transit?c=14173184.1092730,4540214.3919615,18.58,0,0,0,dh" target="_blank">
          <div class="icon">
            <img src="@/assets/images/navermap.png">
          </div>
          <span>네이버지도 길찾기</span>
        </a>
        <a class="guideLink map kakao" href="http://kko.to/0NKTneApDT" target="_blank">
          <div class="icon">
            <img src="@/assets/images/kakaomap.png">
          </div>
          <span>카카오지도 길찾기</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.min.css'
import {Navigation} from "swiper"
import { getBoardList, convertDate } from '@/utils'
import {onMounted, ref} from "vue";

export default {
  name: 'Home',
  components: {
    Swiper, SwiperSlide
  },
  setup () {
    const galleryList = ref([])
    const noticeList = ref([])

    const galleryControl = {
      prevEl: '.homeSlide.gallery > .galleryContainer > .prev',
      nextEl: '.homeSlide.gallery > .galleryContainer > .next'
    }

    onMounted(() => {
      getGalleryItems()
      getNoticeItems()
    })

    const getNoticeItems = () => {
      getBoardList('notice', 1, 5).then((response) => {
        if(response.status == 200) {
          noticeList.value = response.data.slice(0, 5)
        }
      })
    }
    const getGalleryItems = () => {
      getBoardList('gallery', 1, 12).then((response) => {
        if(response.status == 200) {
          galleryList.value = response.data
        }
      })
    }
      return {
      modules: [Navigation], galleryControl, galleryList, convertDate, noticeList
    }
  }
}
</script>

<style>
@import "@/styles/Home.css";
</style>
