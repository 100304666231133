<template>
  <div class="listControl">
    <div class="listPagination">
      <router-link :class="{'button': true, 'move': true, 'prev-10': true, 'disabled': page == 1}" :to="`/post/${type}?page=1`">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" fill="none" viewBox="0 0 32 32">
          <g fill="#000" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M26.06 4.94a1.5 1.5 0 0 1 0 2.12L17.122 16l8.94 8.94a1.5 1.5 0 0 1-2.122 2.12l-10-10a1.5 1.5 0 0 1 0-2.12l10-10a1.5 1.5 0 0 1 2.122 0z"/><path d="M16.06 4.94a1.5 1.5 0 0 1 0 2.12L7.122 16l8.94 8.94a1.5 1.5 0 0 1-2.122 2.12l-10-10a1.5 1.5 0 0 1 0-2.12l10-10a1.5 1.5 0 0 1 2.122 0z"/>
          </g>
        </svg>
      </router-link>

      <router-link :to="`/post/${type}?page=${page - 1}`" :class="{'button': true, 'move': true, 'prev-10': true, 'disabled': page == 1}">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" fill="none" viewBox="0 0 32 32"><path fill="#000" fill-rule="evenodd" d="M21.06 4.94a1.5 1.5 0 0 1 0 2.12L12.122 16l8.94 8.94a1.5 1.5 0 0 1-2.122 2.12l-10-10a1.5 1.5 0 0 1 0-2.12l10-10a1.5 1.5 0 0 1 2.122 0z" clip-rule="evenodd"/></svg>
      </router-link>

      <router-link v-for="num in pagination.pageRange" :key="num"
                   class="button number" :class="page == num ? 'active' : ''"
                   :to="`/post/${type}?page=${num}`"
      >{{ num }}</router-link>

      <router-link :to="`/post/${type}?page=${Number(page) + 1}`" :class="{button: true, move: true, 'next-1': true, disabled: page == pagination.totalPage}">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" fill="none" viewBox="0 0 32 32"><path fill="#000" fill-rule="evenodd" d="M10.94 4.94a1.5 1.5 0 0 1 2.12 0l10 10a1.5 1.5 0 0 1 0 2.12l-10 10a1.5 1.5 0 0 1-2.12-2.12L19.878 16l-8.94-8.94a1.5 1.5 0 0 1 0-2.12z" clip-rule="evenodd"/></svg>
      </router-link>

      <router-link :to="`/post/${type}?page=${pagination.totalPage}`" :class="{button: true, move: true, 'next-10': true, disabled: page == pagination.totalPage}">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" fill="none" viewBox="0 0 32 32"><g fill="#000" fill-rule="evenodd" clip-rule="evenodd"><path d="M5.94 4.94a1.5 1.5 0 0 1 2.12 0l10 10a1.5 1.5 0 0 1 0 2.12l-10 10a1.5 1.5 0 0 1-2.12-2.12L14.878 16l-8.94-8.94a1.5 1.5 0 0 1 0-2.12z"/><path d="M15.94 4.94a1.5 1.5 0 0 1 2.12 0l10 10a1.5 1.5 0 0 1 0 2.12l-10 10a1.5 1.5 0 0 1-2.12-2.12L24.878 16l-8.94-8.94a1.5 1.5 0 0 1 0-2.12z"/></g></svg>
      </router-link>
    </div>
    <div class="listSearch">
      <select class="searchSelect" name="filter">
        <option class="selectOption" value="title" selected>제목</option>
        <option class="selectOption" value="substance">내용</option>
        <option class="selectOption" value="all">제목+내용</option>
      </select>
      <input type="text">
      <button type="submit">
        <img src="@/assets/images/btn-search.svg">
      </button>
    </div>
  </div>
</template>

<script>
import {onMounted, onUpdated, ref, watch} from "vue";
import {useMobileDetection} from "vue3-mobile-detection";
import { getBoardListCount } from '@/utils'

export default {
  name: 'ListControl',
  props: ['type', 'page', 'pageSize'],
  setup (props) {
    const {isMobile} = useMobileDetection()
    const pageBlock = (isMobile() ? 5 : 10)
    const pagination = ref({})
    const totalCount = ref(0)

    const initPagination = () => {
      const totalPage = Math.ceil(totalCount.value / props.pageSize)
      let endPage = Math.ceil(props.page / pageBlock) * pageBlock
      endPage = endPage > totalPage ? totalPage : endPage

      let startPage = endPage - (pageBlock - 1)
      startPage = startPage <= 0 ? 1 : startPage


      const pageRange = Array(endPage - startPage + 1).fill(startPage).map((x, y) => x + y)


      pagination.value = {
        totalCount, totalPage, startPage, endPage, pageRange
      }
    }

    watch(props, (value) => {
      initPagination()
    })

    onMounted(() => {
      getBoardListCount(props.type).then((response) => {
          if(response.status == 200) {
            totalCount.value = response.data
            initPagination()
          }
        }).catch((err) => {
        console.log(err)
      })
    })

    return {
      pagination
    }
  }
}
</script>

<style scoped>
@import "@/styles/ListControl.css";
</style>
