<template>
  <div class="gallery">
    <div class="galleryBody">
      <div class="galleryItem noResult" v-if="list.length == 0">
        <div>게시물이 없습니다.</div>
      </div>
      <div class="galleryItem" v-for="item in list" :key="item.seq">
        <router-link :to="`/post/gallery/detail?seq=${item.seq}`" v-slot="{navigate}" custom>
          <img v-if="item.thumbnail != null" :src="`/api/file/${type}/${item.thumbnail}`" alt="" @click="navigate">
          <img v-else src="@/assets/images/no-image.jpg">
        </router-link>
        <div class="text">
          <div class="title">
            <router-link :to="`/post/gallery/detail?seq=${item.seq}`">{{ item.title }}</router-link>
          </div>
          <div class="date">{{ convertDate(item.regDate )}}</div>
        </div>
      </div>
    </div>
    <div class="galleryBtn">
      <slot name="writeBtn" />
    </div>
    <slot name="paging" v-if="list.length > 0"/>
  </div>
</template>

<script>
import ListControl from '@/components/ListControl.vue'
import { convertDate } from '@/utils'
import {onUpdated} from "vue";

export default {
  name: 'PostGallery',
  components: {ListControl},
  props: ['type', 'page', 'list'],
  setup (props) {
    return {
      convertDate
    }
  }
}
</script>

<style scoped>
@import "@/styles/post.css";
</style>
