<template>
  <div class="tableBoard">
    <div class="table">
      <div class="tableHead">
        <div class="tableColumn number">번호</div>
        <div class="tableColumn title">제목</div>
        <div class="tableColumn date">날짜</div>
        <div class="tableColumn hits">조회수</div>
      </div>
      <div class="tableBody">
        <div class="row noResult" v-if="list.length == 0">
          <div>게시물이 없습니다.</div>
        </div>
        <div class="row" v-else v-for="item in list" :key="item.seq">
          <div class="tableColumn number">{{ item.seq}}</div>
          <div class="tableColumn title">
            <router-link :to="`/post/${type}/detail?seq=${item.seq}`">{{ item.title }}</router-link>
          </div>
          <div class="tableColumn date">{{ convertDate(item.regDate) }}</div>
          <div class="tableColumn hits">{{ item.viewCnt }}</div>
        </div>
      </div>
      <div class="tableBtn">
        <slot name="writeBtn" />
      </div>
    </div>
    <slot name="paging" v-if="list.length > 0"/>
    </div>
</template>

<script>
import { convertDate} from '@/utils'

export default {
  name: 'tableBoard',
  props: ['type', 'list'],
  setup (props) {
    return {
      convertDate
    }
  }
}
</script>

<style scoped>
@import "@/styles/post.css";
</style>
