<template>
  <div class="detailView">
    <input class="inputTitle" type="text" placeholder="제목을 입력해주세요" :disabled="!edit" v-model="item.title">
    <div class="fileManager">
      <div class="fileList" v-if="edit || (!edit && $props.type != 'gallery')">
        <div v-if="getUploadFiles.length > 0" v-for="(item, index) in getUploadFiles" class="fileItem">
          <div :class="{fileName: true, download : !edit}">
            <template v-if="edit">
              {{item.fileName}}
            </template>
            <template v-else>
              <a :href="`/api/file/${type}/${item.savedFileName}`" :download="item.fileName">{{item.fileName}}</a>
            </template>
          </div>
          <button class="fileDelBtn" @click="deleteUploadedFile(index);" v-if="edit">
            <img src="@/assets/images/icon-cross.svg" width="20" height="20"/>
          </button>
        </div>
        <div v-else>첨부파일이 없습니다</div>
      </div>
      <button class="fileUpload" v-if="edit" @click="fileInput.click()">
        <img src="@/assets/images/icon-folder.svg">
        <span>파일 가져오기</span>
        <input type="file" style="display:none;" name="file" ref="fileInput" @change="selectFile" multiple/>
      </button>
    </div>

    <div :class="{inputSubstance: true, editable: edit}" :contenteditable="edit" ref="bodyInput">
      <template v-if="getUploadFiles.length > 0 && detail != 'edit'" v-for="image in getUploadFiles">
        <img v-if="image.fileExt == 'jpg' || image.fileExt == 'png'" :src="`/api/file/${type}/${image.savedFileName}`" />
      </template>

      <div v-if="!edit || detail == 'edit'" v-html="getBodyText(item.body)"></div>

    </div>
    <div class="btnWrap">
      <button class="save" v-if="edit" @click="saveItem">저장</button>
      <template v-if="!edit && isLogined">
        <button class="edit" @click="doEditItem" v-if="false">수정</button>
        <button class="remove" @click="doDeleteItem">삭제</button>
      </template>
      <button class="cancel" @click="doClose">닫기</button>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, onMounted, ref, watch,} from "vue";
import { getBoardDetail, saveItemRequest, deleteItem } from '@/utils'

import { useRoute, useRouter} from "vue-router";
import axios from "axios";

export default {
  name: "DetailView",
  props: ['seq', 'type', 'isLogined'],
  setup (props) {
    const route = useRoute()
    const router = useRouter()

    const {params} = route
    const detail = ref('')
    const bodyInput = ref()

    const fileInput = ref()
    const edit = ref(false)
    const item = ref({title: '', body: ''})
    const uploadFiles = ref([])
    const deleteFiles = ref([])
    const getUploadFiles = computed(() => uploadFiles.value)

    watch(route, (newVal) => {
      detail.value = newVal.params.detail
      edit.value = (detail.value == 'add' || detail.value == 'edit')
      if(edit.value) {
        loadItemOnce()
      }
    })

    const deleteUploadedFile = (index) => {
      deleteFiles.value.push(uploadFiles.value[index].seq)
      uploadFiles.value.splice(index, 1)

    }

    const selectFile = (evt) => {
      let files = evt.currentTarget.files
      for(let i = 0; i < files.length; i++) {
        files[i].fileName = files[i].name
        uploadFiles.value.push(files[i])
      }
    }

    const getBodyText = (text) => {
      if(text) return text.replace(/\n/g, '<br/>')
      else return ''
    }

    const changeBody = (evt) => {
      if(!edit) return

      item.value.body = evt.target.innerText
    }

    const loadItemOnce = () => {
      getBoardDetail(props.type, props.seq).then((response) => {
        if(response.status == 200) {
          item.value = response.data

          if(item.value.attachList) {
            uploadFiles.value = item.value.attachList
          }
        }
      }).catch((err) => {
        console.log(err)
        item.value = {
          title: '', body: ''
        }
      })
    }

    const saveItem = () => {
      const formData = new FormData()
      formData.append('type', props.type)
      formData.append('title', item.value.title)
      formData.append('body', bodyInput.value.innerText)
      if(item.value.hasOwnProperty('seq')) formData.append('seq', item.value.seq)

      Array.from(uploadFiles.value).forEach(item => {
        formData.append('files', item)
      })

      saveItemRequest(props.type, formData).then((response) => {
        if(response.status == 200) {
          router.push(`/post/${props.type}`)
        }else {
          throw Error()
        }
      }).catch((err) => {
        console.log(err)
        item.value = {
          title: '', body: ''
        }
      })
    }

    const doDeleteItem = () => {
      const { seq, type } = item.value

      deleteItem(type, seq).then((response) => {
        if(response.status == 200) {
          router.go(-1)
        }
      }).catch((err) => {
        console.log(err)
      })
    }

    const doEditItem = () => {
      router.push(`/post/${props.type}/edit?seq=${props.seq}`)
    }

    onMounted(() => {
      edit.value = (route.params.detail == 'add' || route.params.detail == 'edit')
      if(!edit.value) {
        loadItemOnce()
      }

    })

    const doClose = () => {
      if(detail.value == 'edit') router.go(-2)
      else router.go(-1)
    }

    return {
      item, params, edit, saveItem, fileInput, getUploadFiles,
      selectFile,changeBody, getBodyText, doDeleteItem, doEditItem, doClose,
      detail, bodyInput, deleteUploadedFile
    }
  }
}
</script>

<style scoped>

</style>
