<template>
  <div class="guide">
    <div class="map">
      <l-map ref="map" v-model:zoom="zoom" :center="center">
        <l-tile-layer
          layer-type="base"
          url="https://mt0.google.com/vt/lyrs=m&hl=ko&x={x}&y={y}&z={z}" />
        <l-marker :lat-lng="center" />
      </l-map>
    </div>
    <div class="explain address">
      <div class="title">
        <img src="@/assets/images/address.svg">
        <span>주소</span>
      </div>
      <div class="text">
        <div class="row"><span class="classification">도로명</span><span>경기도 남양주시 수동면 비룡로908번길 52-19</span></div>
        <div class="row"><span class="classification">지번</span><span>경기도 남양주시 수동면 입석리 603-7</span></div>
      </div>
    </div>
    <div class="explain self">
      <div class="title">
        <img src="@/assets/images/car.svg">
        <span>자가용 이용시</span>
      </div>
      <span class="text">수동초등학교를 지나서 우측에 탑거리 버스정류장 다음 큰길 우회전 (네오비젼스튜디오 표지판)</span>
    </div>
    <div class="explain public">
      <div class="title">
        <img src="@/assets/images/bus.svg">
        <span>대중교통 이용시</span>
      </div>
      <div class="text">버스 : 330-1 (청량리에서 수동까지 운행), 마석역에서 마을버스 운행중(배차간격 있음)<br>
        "탑거리" 버스정류장 하차 후 10m 정도 직진 후 큰길로 쭉 들어오신 다음 입석4리 마을회관 옆길</div>
    </div>
    <div class="btnWrap">
      <a class="map naver" href="https://map.naver.com/v5/directions/-/14173184.109273124,4540214.39196134,%EB%91%90%EB%A6%AC%EC%9B%90,12791087,PLACE_POI/-/transit?c=14173184.1092730,4540214.3919615,18.58,0,0,0,dh" target="_blank">
        <div class="icon">
          <img src="@/assets/images/navermap.png">
        </div>
        <span>네이버지도 길찾기</span>
      </a>
      <a class="map kakao" href="http://kko.to/0NKTneApDT" target="_blank">
        <div class="icon">
          <img src="@/assets/images/kakaomap.png">
        </div>
        <span>카카오지도 길찾기</span>
      </a>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'

export default {
  name: 'IntroGuide',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },

  setup () {
    const center = [37.721908, 127.319866]
    const zoom = 19
    const max = 19
    return {
      center, zoom, max
    }
  }
}
</script>

<style scoped>
@import "@/styles/Introduce.css";
</style>
