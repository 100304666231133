<template>
  <div class="rights">
    <div class="info">
      <div class="item">
        <span class="title">두리원 인권기본이념</span>
        <span class="substance">
          두리원 이용자는 존엄성을 가진 인간으로서 인권을 보장받으며 인간다운 삶을 살아가는 것을 목적으로 한다
        </span>
      </div>
      <div class="item">
        <span class="title">인권지킴이단 구성</span>
        <span class="substance">
          이용자, 보호자, 종사자, 지역주민, 공공후견인, 인권전문가 각 1명</span>
      </div>
      <div class="item">
        <span class="title">인권지킴이단 활동내용</span>
        <span class="substance">매월 이용자 인권상황점검표 작성</span>
      </div>
    </div>
    <div class="system">
      <span class="title">인권침해 의심사례 진정 고발 절차</span>
      <div class="table">
        <div class="step">
          <span class="item">인권침해 의심상황 인지 및 접수</span>
        </div>
        <img src="@/assets/images/angle-down.svg">
        <div class="step">
          <span class="item">중대한 사항</span>
          <span class="item">경미한 사항</span>
        </div>
        <div class="explain">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>이용자 직접적인 의사표현, 인권지킴이단 외부점검, 내부고발, 진정함, 고충함 등</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>사안의 중하거나 경중 판단이 난해할 시, 시 &middot; 도별 인권조사전담팀에 의뢰</span>
          </div>
        </div>
        <img src="@/assets/images/angle-down.svg">
        <div class="step">
          <span class="item">시 &middot; 도별 인권조사전담팀 의뢰</span>
          <span class="item">인권지킴이단 회의</span>
        </div>
        <div class="explain">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>의심상황 공유</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>사실확인 방법과 절차 결정, 사실확인팀 구성</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>선 보호조치</span>
          </div>
        </div>
        <img src="@/assets/images/angle-down.svg">
        <div class="step">
          <span class="item">조사결과에 따른 진정 &middot; 수사의뢰 (인권위, 경찰청)</span>
          <span class="item">침해사실 조사</span>
        </div>
        <div class="explain">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>피해자 면담(사실확인)</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>피의자(행위자) 사실 면담과 확인조사</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>목격자(직원, 이용자 등)의 사실확인</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>자료확보(영상, 사진, 상담기록, 관찰기록 등)</span>
          </div>
        </div>
        <img src="@/assets/images/angle-down.svg">
        <div class="step">
          <span class="item">조사진행</span>
          <span class="item">침해사실여부 확인 및 보고 &middot; 통보</span>
        </div>
        <div class="explain">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>사실확인 결과 경미한 사안(말투, 사소한 다툼 등)의 경우 내부규정에 따라 인사 또는 징계 조치</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>사실확인 후 중대사안으로 판명될 경우 및 판단이 난해한 경우 즉시 시 &middot; 도별 전담팀에 의뢰</span>
          </div>
        </div>
        <img src="@/assets/images/angle-down.svg">
        <div class="step">
          <span class="item">조사결과 보고 &middot; 통보</span>
          <span class="item">사후 관리</span>
        </div>
        <div class="explain">
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>피해자, 피의자, 보호자, 시설, 관할 시군구 등에 조사결과를 인권지킴이단장 명의의 문건으로 보고(통보)</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>인권 침해자에 대한 행정조치(인사위원회 심의결정에 따른 신분 조치) 등을 시설에 요구</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>피해자에 대한 지원조치와 사후 예방계획 수립</span>
          </div>
          <div class="row">
            <img src="@/assets/images/check.svg">
            <span>자료확보(영상, 사진, 상담기록, 관찰기록 등)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="link">
      <a class="btn" href="https://www.humanrights.go.kr" target="_blank">
        <img src="@/assets/images/logo-humanrights.png">
        <span>국가인권위원회</span>
      </a>
      <a class="btn" href="https://ggbpolice.go.kr/nyj/mainPage.do" target="_blank">
        <img src="@/assets/images/logo-police.png">
        <span>남양주 북부 경찰서</span>
      </a>
      <a class="btn" href="https://www.nyj.go.kr/" target="_blank">
        <img src="@/assets/images/logo-namyangju.png">
        <span>남양주시청</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceRights'
}
</script>

<style scoped>

</style>
