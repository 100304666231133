<template>
  <div class="history">
    <div class="timetable">
      <div class="row event odd">
        <div class="icon">
          <img src="@/assets/images/tea-leaf.svg">
        </div>
        <div class="line"></div>
        <div class="card">
          <div class="line"></div>
          <div class="text">
            <span class="date">01.05.01</span>
            <span class="substance">미인가 시설 설치</span>
          </div>
        </div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row event even">
        <div class="icon">
          <img src="@/assets/images/add-user.svg">
        </div>
        <div class="line"></div>
        <div class="card">
          <div class="line"></div>
          <div class="text">
            <span class="date">03.05.01</span>
            <span class="substance">장애인 12명으로 인원 확충</span>
          </div>
        </div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row event odd">
        <div class="icon">
          <img src="@/assets/images/exchange.svg">
        </div>
        <div class="line"></div>
        <div class="card">
          <div class="line"></div>
          <div class="text">
            <span class="date">06.11.28</span>
            <span class="substance">장애인 신고시설로 전환</span>
          </div>
        </div>
      </div>
      <div class="row skip">
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="line"></div>
      </div>
      <div class="row event even">
        <div class="icon">
          <img src="@/assets/images/badge.svg">
        </div>
        <div class="line"></div>
        <div class="card">
          <div class="line"></div>
          <div class="text">
            <span class="date">19.12.27</span>
            <span class="substance">사회복지 법인 인가</span>
          </div>
        </div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row no-event">
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="row event odd">
        <div class="icon">
          <img src="@/assets/images/care.svg">
        </div>
        <div class="line"></div>
        <div class="card">
          <div class="line"></div>
          <div class="text">
            <span class="date">23.01 ~</span>
            <span class="substance">현재 21명 이용자 생활중</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroHistory'
}
</script>

<style scoped>
@import "@/styles/Introduce.css";
</style>
