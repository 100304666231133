<template>
  <div id="main" class="pageService">
    <span class="pageTitle">서비스지원</span>
    <div class="navBar">
      <router-link class="nav" to="/service">프로그램</router-link>
      <router-link class="nav" to="/service/rights">인권지킴이단</router-link>
    </div>
    <div id="pageContent">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service'
}
</script>

<style scoped>
@import "@/styles/Service.css";
</style>
