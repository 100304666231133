<template>
  <div class="greeting">
    <div class="head">
      <div class="comment">
        <span>"함께가는 아름다운 세상"</span>
        <span>건강한 복지를 실천하는</span>
        <span>두리원이 되도록 하겠습니다</span>
      </div>
      <div class="who">
        <span>대표이사&nbsp;</span>
        <span>김선숙</span>
      </div>
      <img src="@/assets/images/ceo.jpg">
    </div>
    <span class="text">
                    장애를 갖고 살아오면서 "그 무엇보다도 직업이 중요하다"라는 의지아래 22년 전 직업재활로 시작한 장애인복지가 지금의 거주시설인 두리원이 되었습니다.<br><br>
                    장애를 갖고 살면서 내가 바라던 삶은 무엇인가를 늘 고민했던 그 마음으로 두리원 이용인들을 생각합니다.<br><br>
                    음악이 나오면 춤추고, 맛있는 음식을 먹을 때 행복해하고, 슬프면 눈물을 떨구고, 화나면 소리 지르는 친구들을 보며 저는 항상 인생을 배웠습니다.<br>
                    그런 배움을 통해 살아가는 우리의 삶에 복잡한 계산과 이해관계들이 아닌, 있는 그대로의 모습으로 사회복지의 문을 열었습니다.<br><br>
                    이제 급변하는 사회복지 흐름에 맞춰 새로운 도약을 하고자 합니다.<br>
                    이용인으로서 자기결정권을 가지고 누려야 될 권리와 개인의 욕구충족을 위해 교육, 문화, 체육, 예술 등 활동적인 프로그램으로 운영되는 두리원이 되고자 합니다.<br><br>
                    오늘의 두리원이 있기까지 후원과 봉사, 그리고 기도로 함께해 주신 많은 분들께 가슴깊이 감사드립니다.<br><br>
                    앞으로도 건강한 복지를 실천해 가는 두리원이 되겠습니다.
                </span>
    <div class="who">
      <span>사회복지법인 두리원 대표이사&nbsp;</span>
      <span>김선숙</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroGreeting'
}
</script>

<style scoped>
@import "@/styles/Introduce.css";
</style>
