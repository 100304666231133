<template>
  <teleport to="#modal" :disabled="disabled">
    <div class="dialog" v-show="isOpen">
      <div class="title">Login</div>
      <div class="content">
        <input type="text" placeholder="아이디" ref="idInput">
        <input type="password" placeholder="비밀번호" ref="pwInput" @keyup.enter="doLogin">
      </div>
      <div class="footer">
        <button class="btnLogin" @click="doLogin">로그인</button>
        <button class="btnClose" @click="hide">닫기</button>
      </div>
    </div>
  </teleport>
</template>

<script>
import {login} from "@/utils";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "ModalComponent",
  setup () {
    const router = useRouter()
    const logined = ref(false)
    const isOpen = ref(false)
    const disabled = ref(true)
    const idInput = ref(null)
    const pwInput = ref(null)

    const doLogin = () => {
      const id = idInput.value.value
      const pw = pwInput.value.value

      login(id, pw).then((response) => {
        if(response.status == 200) {
          logined.value = true
          router.go(0)
        }
      }).catch((err) => {
        console.log(err)
      })
    }

    const hide = () => {
      isOpen.value = false
      disabled.value = true
    }

    const show = () => {
      isOpen.value = true
      disabled.value = false
      idInput.value.click()
    }

    onMounted(() => {

    })

    return {
      disabled, isOpen, hide, show, doLogin, idInput, pwInput
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_screen.scss";
.dialog {
  width: calc(100% - 2rem);
  max-width: calc($screen-m - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: .3125rem;
  background: #FFFFFF;
  box-shadow: 0 0 .5rem #000000;

  > .title {
    width: 100%;
    height: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background: #5BBDC4;
    color: #FFFFFF;
  }

  > .content {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

    > input {
      width: calc(100% - 2rem);
      height: 2rem;
      padding: 0 .5rem;
      border: $border-primary #A6A6A6;
      border-radius: .3125rem;
      outline: $border-thick transparent;
      font-size: 1rem;

      &:focus {
        outline-color: #5BBDC4;
      }
    }
  }

  > .footer {
    width: 100%;
    padding: 0 1rem .5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    > button {
      width: 50%;
      height: 1.75rem;
      border: $border-primary;
      border-radius: .3125rem;
      background: #FFFFFF;
      outline: none;
      font-size: .875rem;

      &.btnClose {
        border-color: #F24141;
        color: #F24141;
      }

      &.btnLogin {
        border-color: #41B3EA;
        color: #41B3EA;

      }
    }
  }
}
</style>
