const TOKEN_ID = 'QNTOKEN'

export const getToken = () => {
  return window.localStorage.getItem(TOKEN_ID)
}

export const saveToken = (token) => {
  window.localStorage.setItem(TOKEN_ID, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_ID)
}

export const isAuthenticated = () => {
  return getToken() != undefined && getToken() != ''
}

export default {
  getToken,
  saveToken,
  destroyToken,
  isAuthenticated
}
