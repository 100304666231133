<template>
  <div id="header">
    <div class="headerSmall">
      <router-link class="headerLogo" to="/">
        <img src="@/assets/images/logo-duriwon.png">
      </router-link>
      <div class="headerMenu">
        <a class="menuBtn">
          <img src="@/assets/images/icon-menu.svg" @click="clickMenu">
        </a>
        <div class="menuDropdown">
          <div class="navBar">
            <div class="navCategory">
              <a class="navBtn" @click="menuOpen('introduce')">시설소개</a>
              <div class="navDropdown" :class="{active: activeMenu == 'introduce'}">
                <router-link class="dropdownBtn" to="/introduce">법인소개</router-link>
                <router-link class="dropdownBtn" to="/introduce/greeting">시설장 인사말</router-link>
                <router-link class="dropdownBtn" to="/introduce/history">시설연혁</router-link>
                <router-link class="dropdownBtn" to="/introduce/guide">오시는 길</router-link>
              </div>
            </div>
            <div class="navCategory">
              <a class="navBtn" @click.stop="menuOpen('service')">서비스지원</a>
              <div class="navDropdown" :class="{active: activeMenu == 'service'}">
                <router-link class="dropdownBtn" to="/service">프로그램</router-link>
                <router-link class="dropdownBtn" to="/service/rights">인권지킴이단</router-link>
              </div>
            </div>
            <div class="navCategory">
              <a class="navBtn" @click="menuOpen('support')">후원 및 자원봉사</a>
              <div class="navDropdown" :class="{active: activeMenu == 'support'}">
                <router-link class="dropdownBtn" to="/support">후원안내</router-link>
                <router-link class="dropdownBtn" to="/support/volunteer">자원봉사안내</router-link>
              </div>
            </div>
            <div class="navCategory">
              <a class="navBtn" @click.stop="menuOpen('post')">게시판</a>
              <div class="navDropdown" :class="{active: activeMenu == 'post'}">
                <router-link class="dropdownBtn" to="/post/notice">공지사항</router-link>
                <router-link class="dropdownBtn" to="/post/free">자유게시판</router-link>
                <router-link class="dropdownBtn" to="/post/gallery">갤러리</router-link>
              </div>
            </div>
            <div class="navCategory">
              <button class="loginBtn" v-if="!logined" @click="loginOpen">로그인</button>
              <button class="loginBtn" v-else @click="doLogout">로그아웃</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="headerWide">
      <router-link class="headerLogo" to="/">
        <img src="@/assets/images/logo-duriwon.png">
      </router-link>
      <div class="navBar">
        <div class="navCategory">
          <a class="navBtn">시설소개</a>
          <div class="navDropdown">
            <div>
              <router-link class="dropdownBtn" to="/introduce">법인소개</router-link>
              <router-link class="dropdownBtn" to="/introduce/greeting">시설장 인사말</router-link>
              <router-link class="dropdownBtn" to="/introduce/history">시설연혁</router-link>
              <router-link class="dropdownBtn" to="/introduce/guide">오시는 길</router-link>
            </div>
          </div>
        </div>
        <div class="navCategory">
          <a class="navBtn">서비스지원</a>
          <div class="navDropdown">
            <div>
              <router-link class="dropdownBtn" to="/service">프로그램</router-link>
              <router-link class="dropdownBtn" to="/service/rights">인권지킴이단</router-link>
            </div>
          </div>
        </div>
        <div class="navCategory">
          <a class="navBtn">후원 및 자원봉사</a>
          <div class="navDropdown">
            <div>
              <router-link class="dropdownBtn" to="/support">후원안내</router-link>
              <router-link class="dropdownBtn" to="/support/volunteer">자원봉사안내</router-link>
            </div>
          </div>
        </div>
        <div class="navCategory">
          <a class="navBtn">게시판</a>
          <div class="navDropdown">
            <div>
              <router-link class="dropdownBtn" to="/post/notice">공지사항</router-link>
              <router-link class="dropdownBtn" to="/post/free">자유게시판</router-link>
              <router-link class="dropdownBtn" to="/post/gallery">갤러리</router-link>
            </div>
          </div>
        </div>
        <div class="navLogin">
          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 32 32"><path d="M29 1H3a2 2 0 0 0-2 2v8c0 1.097.903 2 2 2s2-.903 2-2V5h22v22H5v-6a2 2 0 0 0-4 0v8a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2z"/><path d="M7 16c0 1.097.903 2 2 2h8.172l-1.586 1.585a2.01 2.01 0 0 0 0 2.829 2.01 2.01 0 0 0 2.828 0l5-5a2 2 0 0 0 0-2.829l-5-5a2 2 0 0 0-2.828 2.83L17.172 14H9c-1.097 0-2 .903-2 2z"/></svg>
          <div class="navDropdown">
            <form @submit="doLogin" v-if="!logined">
                <label class="id">
                  <span>아이디</span>
                  <input type="text" ref="idInput">
                </label>
                <label class="password">
                  <span>비밀번호</span>
                  <input type="password" ref="pwInput">
                </label>
                <label class="control">
                  <a class="findPassword">비밀번호찾기</a>
                  <button >로그인</button>
                </label>
            </form>
            <form @submit="doLogout" v-else>
              <label class="control">
                <button >로그아웃</button>
              </label>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUpdated, ref, watch, watchEffect} from "vue";
import {login, logout, getLoginFlag, loginCheck, setLoginFlag} from '@/utils'
import {useRoute, useRouter} from "vue-router";
import jwt from '@/utils/jwt'

export default {
  name: 'Header',
  props: ['modal'],
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const activeMenu = ref('')
    const idInput = ref()
    const pwInput = ref()
    const logined = ref()

    watch(route, (newVal) => {
      window.scrollTo(0,0)
    })

    watchEffect(() => {
      logined.value = jwt.isAuthenticated()
    })

    const menuOpen = (menu) => {
      activeMenu.value = menu
    }

    const doLogin = (evt) => {
      if(evt) evt.preventDefault();
      const id = idInput.value.value
      const pw = pwInput.value.value

      login(id, pw).then((response) => {
        if(response.status == 200) {
          logined.value = true
          jwt.saveToken(response.data)
          router.go(0)
        }
      }).catch((err) => {
        console.log(err)
      })
    }

    const doLogout = (evt) => {
      evt.preventDefault();
      jwt.destroyToken()
      router.go(0)
    }

    const loginOpen = () => {
      props.modal.show()
    }

    const clickMenu = (evt) => {
      evt.stopPropagation()
      const target = document.querySelector(".headerSmall .headerMenu")

      const {classList} = target

      if(classList.contains('active')) classList.remove('active')
      else classList.add('active')
    }

    onMounted(() => {
      document.querySelectorAll('.dropdownBtn').forEach((item) => {
        item.addEventListener('click', (evt) => {
          const target = document.querySelector(".headerSmall .headerMenu")
          target.classList.remove('active')
        })
      })
    })

    return {
      menuOpen, activeMenu, idInput, pwInput, doLogin, doLogout, logined, loginOpen, clickMenu
    }
  }
}
</script>

<style>
@import "@/styles/Header.css";


</style>
