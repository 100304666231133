<template>
  <div class="programs">
    <div class="card exercise">
      <div class="title">
        <img src="@/assets/images/fitness.svg">
        <span>운동재활</span>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">배드민턴</span>
          <span class="how">매주 2회, 강사의 지도로 1회에 약 2시간씩 진행</span>
          <span class="explain">점프와 회전을 통해 허리와 목, 어깨, 팔다리에 통증이 생기는 근골격계 질환을 예방하는 데 매우 효과적이며, 공을 바라보며 타격함으로써 집중력과 정신적 스트레스 해소에 좋음</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/badminton-1.png">
          <img src="@/assets/images/badminton-2.png">
          <img src="@/assets/images/badminton-3.png">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">볼링</span>
          <span class="how">매주 1회, 담당 선생님과 함께 1회에 약 2시간씩 진행</span>
          <span class="explain">볼의 무게를 이용한 투구 동작은 적당한 전신운동으로 평소의 운동부족 해소에 도움이 되며, 팀을 구성하여 하는 운동으로써 상호간 친화력 강화를 시켜줌</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/bowling-1.png">
          <img src="@/assets/images/bowling-2.png">
          <img src="@/assets/images/bowling-3.png">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">난타</span>
          <span class="how">매주 1회, 강사님과 함께 1회에 약 1시간씩 진행</span>
          <span class="explain">두드림은 일상 속의 스트레스와 피로를 씻어내고 활기찬 생체리듬을 차장주는 활동으로 스트레스를 해소하는 데 도움이 됨</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/nanta-1.png">
          <img src="@/assets/images/nanta-2.png">
          <img src="@/assets/images/nanta-3.png">
        </div>
      </div>
    </div>
    <div class="card mind">
      <div class="title">
        <img src="@/assets/images/smile.svg">
        <span>심리정서재활</span>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">동물농장</span>
          <span class="how">동물에게 직접 사료를 주고, 청소를 하며 애착관계 형성</span>
          <span class="explain">시설 내 고양이를 직접 키우며 동물과 유대감과 애착관계를 형성함으로써 스스로 누군가를 보살피면서 심리적인 안정감을 얻음</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/emotion-1.png">
          <img src="@/assets/images/emotion-2.png">
          <img src="@/assets/images/emotion-3.png">
        </div>
      </div>
    </div>
    <div class="card society">
      <div class="title">
        <img src="@/assets/images/inspiration.svg">
        <span>사회재활</span>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">지역연계활동</span>
          <span class="how">지역축제 등 다양한 연계 프로그램 진행</span>
          <span class="explain">다양한 경험과 문화체험을 하여 자립심과 자신감을 형성하며, 지역사회와 연계를 통해 비장애인들과 함께 프로그램을 참여함으로써 사회적응력을 향상시킴</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/local-1.png">
          <img src="@/assets/images/local-2.png">
          <img src="@/assets/images/local-3.png">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">일상생활 지원</span>
          <span class="how">장보기, 요리, 빨래 등 일상생활을 지원</span>
          <span class="explain">스스로 마트에 가서 물건을 구입하고, 요리, 빨래, 청소 등을 함으로써 스스로 해낼 수 있다는 자립감과 자존감을 형성함</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/daily-1.png">
          <img src="@/assets/images/daily-2.png">
          <img src="@/assets/images/daily-3.png">
        </div>
      </div>
    </div>
    <div class="card etc">
      <div class="title">
        <img src="@/assets/images/star2.svg">
        <span>기타 프로그램</span>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">생일파티</span>
          <span class="how">매월 말 해당 월 생일을 맞이한 이용자들을 위한 파티</span>
          <span class="explain">케이크와 간식을 준비해 생일파티를 함으로써 소속감과 자존감을 형성</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/birthday-1.png">
          <img src="@/assets/images/birthday-2.png">
          <img src="@/assets/images/birthday-3.png">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">산책</span>
          <span class="how">매주 2회, 선생님과 함께 1회에 약 2시간씩 진행</span>
          <span class="explain">산책은 치매예방에 도움이 되고 인지기능저하를 예방하는 데 효과적이며 에너지 대사를 촉진하여 혈당을 낮추는 효과가 있음. 자연과 바람을 직접 몸으로 느끼며 기분전환을 하는 것은 심리적 안정감을 높여 줌</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/walk-1.png">
          <img src="@/assets/images/walk-2.png">
          <img src="@/assets/images/walk-3.png">
        </div>
      </div>
      <div class="item">
        <div class="text">
          <span class="name">여행</span>
          <span class="how">분기별 여행을 통한 다양한 경험</span>
          <span class="explain">산과 바다, 계곡 등 다양한 자연으로 여행을 다니며 시야를 넓히고 다양한 경험을 통해 스트레스 해소와 심리적 안정감을 형성</span>
        </div>
        <div class="photo">
          <img src="@/assets/images/travel-1.png">
          <img src="@/assets/images/travel-2.png">
          <img src="@/assets/images/travel-3.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicePrograms'
}
</script>

<style scoped>

</style>
