<template>
  <div id="main" class="pageIntroduce">
    <span class="pageTitle">시설소개</span>
    <div class="navBar">
      <router-link class="nav" to="/introduce">법인소개</router-link>
      <router-link class="nav" to="/introduce/greeting">시설장 인사말</router-link>
      <router-link class="nav" to="/introduce/history">시설연혁</router-link>
      <router-link class="nav" to="/introduce/guide">오시는 길</router-link>
    </div>
    <div id="pageContent">
      <router-view ></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduce'
}
</script>

<style scoped>
@import "@/styles/Introduce.css";
</style>
