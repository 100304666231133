<template>
  <div class="donate">
    <div class="head">
      <img src="@/assets/images/plant.svg">
      <span>"두리원은 21명의 이용자들이 함께 살아가는 보금자리입니다.<br>여러분의 사랑과 관심을 통해 두리원 이용자들에게 더 행복한 삶을 선물해주세요"</span>
    </div>
    <div class="how">
      <div class="item normal">
        <span class="title">일반후원</span>
        <div class="text">
          <div class="substance">
            <span class="title">CMS 자동이체</span>
            <span>CMS 신청서를 작성하여 은행에 방문 하지 않고 두리원 통장으로 이체</span>
          </div>
          <div class="substance">
            <span class="title">자동이체 및 무통장입금</span>
            <span>두리원 후원 전용 계좌로 입금</span>
          </div>
        </div>
      </div>
      <div class="item product">
        <span class="title">물품후원</span>
        <div class="text">
          <div class="substance">
            <span class="title">식품류</span>
            <span>쌀, 고기, 계란 등</span>
          </div>
          <div class="substance">
            <span class="title">간식류</span>
            <span>과자, 음료수, 과일 등</span>
          </div>
          <div class="substance">
            <span class="title">사무용품</span>
            <span>컴퓨터, 복사기 등 각종 사무용품</span>
          </div>
          <div class="substance">
            <span class="title">생필품</span>
            <span>치약, 칫솔, 수건 등</span>
          </div>
          <div class="substance">
            <span class="title">의료용품</span>
            <span>운동기구, 기저귀, 의약품 등</span>
          </div>
        </div>
      </div>
      <div class="item visit">
        <span class="title">방문후원</span>
        <div class="text">
          <div class="substance">
            <span>두리원에 방문하여 직접 전달하는 방식</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="item">
        <span class="title">후원계좌</span>
        <span>예금주 : 두리원 / 204084-51-063145 (농협)</span>
      </div>
      <div class="item">
        <span class="title">상담전화</span>
        <span>031-593-5636</span>
      </div>
      <div class="item">
        <span class="title">후원혜택</span>
        <span>후원해주신 후원금(품)은 법인세법 24조와 소득세법 34조에 의해 연말정산시 소득공제 혜택을 받으실 수 있으십니다.<br>(기부금 영수증 발급)</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportDonate'
}
</script>

<style scoped>

</style>
