<template>
  <div id="main" class="pageSupport">
    <span class="pageTitle">후원 및 자원봉사</span>
    <div class="navBar">
      <router-link class="nav" to="/support">후원안내</router-link>
      <router-link class="nav" to="/support/volunteer">자원봉사안내</router-link>
    </div>
    <div id="pageContent">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Support'
}
</script>

<style scoped>
@import "@/styles/Support.css";
</style>
